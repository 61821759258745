/* Regular weight */
@font-face {
  font-family: "walk4Dyslexia"; /* Common name for both fonts */
  src: url("./assets/fonts/regular.ttf") format("truetype");
  font-weight: 400; /* Specify the weight for regular */
  font-style: normal;
}

/* Bold weight */
@font-face {
  font-family: "walk4Dyslexia"; /* Same font-family name */
  src: url("./assets/fonts/bold.otf") format("truetype");
  font-weight: 700; /* Specify the weight for bold */
  font-style: normal;
}

body {
  margin: 0;
  font-family: "walk4Dyslexia", sans-serif !important;
}
